<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12">
        <Form
          :validation-schema="validationSchema"
          @submit="handleRfqForm"
          as="div"
          id="rfq_form"
          class="mb-10 d-flex flex-lg-row flex-column"
        >
          <div class="order-lg-1 order-sm-1 flex-fill">
            <div class="row">
              <div id="rfqNoWrapper" class="mb-5 col-4">
                <label for="rfqNo" class="required form-label">{{ translate('rfq.rfqNo') }}</label>
                <Field v-model="rfqNo" type="text" name="rfqNo" v-slot="{ field }">
                  <input v-bind="field" type="text" class="form-control form-control-solid" />
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="rfqNo" />
                  </div>
                </div>
              </div>

              <div id="rfqDeadlineDateWrapper" class="mb-5 col-4">
                <div class="d-flex flex-column my-md-0">
                  <label
                    for="rfqDeadlineDate"
                    class="form-label required"
                  >{{ translate('rfq.overview.deadlineDate') }}</label>
                  <Field v-model="deadlineDate" name="rfqDeadlineDate" v-slot="{ field }">
                    <ElDatePicker
                      class="w-100"
                      :key="componentKey"
                      v-bind="field"
                      :is-range="true"
                      type="daterange"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start"
                      end-placeholder="End"
                      :format="setDateFormat(currentLang)"
                    ></ElDatePicker>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="rfqDeadlineDate" />
                    </div>
                  </div>
                </div>
              </div>

              <div id="rfqWorkDateWrapper" class="mb-5 col-4">
                <div class="d-flex flex-column my-md-0">
                  <label
                    for="rfqWorkDate"
                    class="form-label required"
                  >{{ translate('rfq.overview.workDate') }}</label>
                  <Field v-model="workDate" name="rfqWorkDate" v-slot="{ field }">
                    <ElDatePicker
                      class="w-100"
                      :key="componentKey"
                      v-bind="field"
                      :is-range="true"
                      type="daterange"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start"
                      end-placeholder="End"
                      :format="setDateFormat(currentLang)"
                    ></ElDatePicker>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="rfqWorkDate" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div id="rfqDescriptionWrapper" class="mb-5 col-12">
                <label
                  for="rfqDescription"
                  class="required form-label"
                >{{ translate('rfq.rfqDescription') }}</label>
                <Field
                  v-model="rfqDescription"
                  type="text"
                  name="rfqDescription"
                  v-slot="{ field }"
                >
                  <textarea v-bind="field" type="text" class="form-control form-control-solid"></textarea>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="rfqDescription" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="min-w-200px min-h-200px order-lg-3 order-sm-2 flex-fill">
            <h4>{{ translate('rfq.uploadedDoc') }}</h4>

            <div class="border border-1 min-h-200px">
              <a class="d-block px-3 mt-3" href="#">
                <inline-svg src="media/icons/duotone/General/Clip.svg" class="me-2"></inline-svg>
                <span>Download Certificate</span>
              </a>
              <a class="d-block px-3 my-3" href="#">
                <inline-svg src="media/icons/duotone/General/Clip.svg" class="me-2"></inline-svg>
                <span>Download Certificate</span>
              </a>
            </div>
          </div>

          <div
            class="d-flex align-items-center mx-10 order-lg-2 order-sm-3 flex-fill justify-content-center"
          >
            <button
              @click="handleRfqForm"
              class="btn btn-primary btn-lg rounded-circle w-150px h-150px"
              type="button"
              data-kt-element="send"
            >{{ translate('rfq.overview.startRound') }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div>
    <TableSkeleton v-show="loading" />
    <AgGridFP
      v-show="!loading"
      id="table1"
      ag-theme="ag-theme-alpine"
      widget-classes="card-xxl-stretch mb-xl-3"
      :aggrid-data="rfqData"
      :enable-card-header="false"
      :footer-row-data="pinnedBottomRowData"
      :custom-column-defs="customColumnDefs"
      :custom-body-options="bodyOptions"
      :enable-row-group="false"
      :enableStatusBar="true"
    ></AgGridFP>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { OverviewSetup } from "./Overview";
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";

import { ElDatePicker } from "element-plus";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "RFQ Overview",
  components: {
    AgGridFP,
    ElDatePicker,
    TableSkeleton,
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const {
      handleRfqForm,
      validationSchema,
      gridApi,
      colApi,
      rfqData,
      loading,
      bodyOptions,
      customColumnDefs,
      pinnedBottomRowData,
      setDateFormat,
      componentKey,
      currentLang,
      rfqNo,
      rfqDescription,
      workDate,
      deadlineDate,
      translate,
    } = OverviewSetup();

    return {
      handleRfqForm,
      validationSchema,
      gridApi,
      colApi,
      setDateFormat,
      componentKey,
      currentLang,
      rfqData,
      rfqNo,
      rfqDescription,
      workDate,
      deadlineDate,
      loading,
      bodyOptions,
      customColumnDefs,
      pinnedBottomRowData,
      translate
    }
  }

});
</script>

<style>
.el-date-editor > * {
  flex: 1 1 auto;
}
</style>
