
import { defineComponent, onMounted } from "vue";
import { OverviewSetup } from "./Overview";
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";

import { ElDatePicker } from "element-plus";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "RFQ Overview",
  components: {
    AgGridFP,
    ElDatePicker,
    TableSkeleton,
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const {
      handleRfqForm,
      validationSchema,
      gridApi,
      colApi,
      rfqData,
      loading,
      bodyOptions,
      customColumnDefs,
      pinnedBottomRowData,
      setDateFormat,
      componentKey,
      currentLang,
      rfqNo,
      rfqDescription,
      workDate,
      deadlineDate,
      translate,
    } = OverviewSetup();

    return {
      handleRfqForm,
      validationSchema,
      gridApi,
      colApi,
      setDateFormat,
      componentKey,
      currentLang,
      rfqData,
      rfqNo,
      rfqDescription,
      workDate,
      deadlineDate,
      loading,
      bodyOptions,
      customColumnDefs,
      pinnedBottomRowData,
      translate
    }
  }

});
