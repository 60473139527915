<template>
  <div class="d-inline-flex flex-grow-1 flex-fill flex-row align-items-center">
    <div class="ag-header-group-cell-label py-3">
      <span class="ag-header-group-text">{{ value.displayName }}</span>
      <span
        class="ag-header-icon ag-header-expand-icon"
        :class="groupExpanded ? 'ag-header-expand-icon-expanded' : 'ag-header-expand-icon-collapsed'"
        v-on:click="expandOrCollapse"
      >
        <span
          class="ag-icon ag-icon-expanded"
          unselectable="on"
          role="presentation"
          v-if="groupExpanded"
        ></span>
        <span class="ag-icon ag-icon-contracted" unselectable="on" role="presentation" v-else></span>
      </span>
    </div>
    <ElDropdown>
      <span class="el-dropdown-link">
        <button class="btn btn-sm btn-icon btn-active-light-dark rounded-circle">
          <span class="svg-icon svg-icon-3 svg-icon-primary">
            <inline-svg src="media/icons/duotone/General/Other1.svg" />
          </span>
        </button>
      </span>
      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem>{{ translate('rfq.overview.commitment') }}</ElDropdownItem>
          <ElDropdownItem>{{ translate('rfq.overview.viewResponse') }}</ElDropdownItem>
          <!-- <el-dropdown-item divided>Select for</el-dropdown-item> -->
        </ElDropdownMenu>
      </template>
    </ElDropdown>

    <!-- <button class="btn btn-sm btn-icon btn-active-light-dark rounded-circle me-3">
      <span class="svg-icon svg-icon-3 svg-icon-primary"></span>
      <inline-svg src="media/icons/duotone/General/Other1.svg" />
    </button>-->
    <!-- <div class="d-inline-flex justify-content-between">
      <button
        type="button"
        class="btn btn-sm btn-outline-dark"
        style="border: 1px solid #181C32"
      >+ Commitment</button>
      <button
        type="button"
        class="btn btn-sm btn-outline-dark"
        style="border: 1px solid #181C32"
      >Open Response</button>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from "element-plus";
import { translate } from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridActions",
  props: ['params'],
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  setup(props) {
    const value = ref(props.params);
    const groupExpanded = ref(false);

    const expandOrCollapse = () => {
      const currentState = value.value.columnGroup
        .getOriginalColumnGroup()
        .isExpanded();
      value.value.setExpanded(!currentState);
    }

    const syncExpandButtons = () => {
      groupExpanded.value = value.value.columnGroup
        .getOriginalColumnGroup()
        .isExpanded();
    }

    onMounted(() => {
      value.value.columnGroup
        .getOriginalColumnGroup()
        .addEventListener('expandedChanged', syncExpandButtons.bind(this));
      syncExpandButtons();
    })

    onUnmounted(() => {
      value.value.columnGroup
        .getOriginalColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons.bind(this));
    })

    return {
      expandOrCollapse,
      groupExpanded,
      value,
      translate,
    };
  },
});
</script>

<style>
.ag-icon-contracted::before {
  content: "\f10b";
}
</style>