import RFQ_DATA from "@/assets/json/rfq.json";

import {
  GridOptions,
  ColGroupDef,
  GridApi,
  ColumnApi,
} from "ag-grid-community";

import { ref, onMounted, computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import {
  formatNumbers,
  formatDates,
  translate,
  currentLang,
  setDateFormat,
} from "@/core/helpers/config";

import OverviewHeader from "./Header.vue";
import GroupHeader from "./GroupHeader.vue";
import AgGridSwitch from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridSwitch.vue";
import router from "@/router/clean";

export const OverviewSetup = () => {
  const rfqData = ref();
  const store = useStore();
  const loading = ref(true);
  const gridApi = ref<GridApi>();
  const colApi = ref<ColumnApi>();

  const componentKey = computed(() => store.state.BodyModule.componentKey);
  let supplierKeys;

  const bodyOptions = reactive<GridOptions>({
    editType: "",
    rowSelection: "multiple",
    enableRangeSelection: true,
    enableFillHandle: false,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    groupHeaderHeight: 75,
    valueCacheNeverExpires: true,
    onGridReady: (params) => {
      gridApi.value = params.api;
      colApi.value = params.columnApi;
    },
  });

  const customColumnDefs = ref<ColGroupDef[]>([
    {
      headerName: translate("rfq.overview.myQuote"),
      marryChildren: true,
      children: [
        {
          hide: false,
          editable: false,
          pinned: "left",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          suppressMenu: true,
          lockPosition: true,
          maxWidth: 60,
          minWidth: 60,
        },
        {
          field: "id",
          colId: "id",
          hide: true,
          editable: false,
          pinned: "left",
        },
        {
          field: "cost_account",
          headerName: translate("rfq.overview.costAccount"),
          colId: "cost_account",
          columnGroupShow: "open",
          editable: false,
          pinned: "left",
        },
        {
          field: "name",
          headerName: translate("rfq.name"),
          colId: "name",
          maxWidth: 150,
          editable: false,
          pinned: "left",
        },
        {
          field: "actual_unit",
          headerName: translate("rfq.unit"),
          colId: "actual_unit",
          maxWidth: 130,
          editable: false,
          pinned: "left",
        },
        {
          field: "hide_qty",
          headerName: translate("rfq.overview.showHideQty"),
          colId: "hide_qty",
          maxWidth: 120,
          editable: true,
          singleClickEdit: true,
          pinned: "left",
          headerTooltip: "Hide the Quantity from the Suppliers",
          cellRendererFramework: AgGridSwitch,
          cellRendererParams: {
            disabled: true,
          },
          cellEditorFramework: AgGridSwitch,
        },
        {
          field: "actual_qty",
          headerName: translate("rfq.quantity"),
          colId: "actual_qty",
          maxWidth: 130,
          editable: false,
          pinned: "left",
        },
        {
          field: "actual_budget",
          headerName: translate("rfq.budget"),
          colId: "actual_budget",
          maxWidth: 150,
          editable: false,
          pinned: "left",
          valueFormatter: (params) => {
            if (!params.value) return "";
            return formatNumbers(+params.value);
          },
        },
      ],
    },
  ]);
  /* eslint-disable @typescript-eslint/class-name-casing */
  /* eslint-disable @typescript-eslint/camelcase */
  const pinnedBottomRowData = ref<any[]>([]);

  const totalColumn = (args: string) => {
    const array = [] as any;
    // Loop rfq data
    rfqData.value.forEach((rfq) => {
      // Get keys for supplier object
      for (const key in rfq["suppliers"]) {
        if (key === args) {
          array.push(rfq["suppliers"][key]);
        }
      }
    });
    // Convert array to values
    const total = array.map((supplier) => supplier["actual_budget"]);
    return formatNumbers(
      total.reduce(function(a, b) {
        return +a + +b;
      }, 0)
    );
  };

  const getSupplierKeys = () => {
    rfqData.value.forEach((rfq) => {
      supplierKeys = Object.keys(rfq.suppliers);
    });
  };

  const handleColumnDefs = () => {
    const columnDefs = [] as any;

    // Create grouped column header
    supplierKeys.forEach((key) => {
      const obj = {
        headerName: key as string,
        marryChildren: true,
        autoHeight: true,
        wrapText: true,
        children: [],
        headerGroupComponentFramework: GroupHeader,
        headerGroupComponentParams: {
          columnGroupName: key,
        },
      };
      columnDefs.push(obj);
    });
    // Push static column definition

    for (let z = 0; z < columnDefs.length; z++) {
      const column = columnDefs[z];

      if (column.headerName !== "My Quote") {
        column["children"].push({
          headerName: translate("rfq.budget"),
          field: "actual_budget",
          columnGroupShow: "open",
          valueGetter: (params) => {
            if (!params) return;
            return rfqData.value[params.node.rowIndex].suppliers[
              column.headerName
            ]["actual_budget"];
          },
          valueFormatter: (params) => {
            if (!params.value) return "";
            return formatNumbers(+params.value);
          },
        });
        column["children"].push({
          headerName: translate("rfq.quantity"),
          field: "actual_qty",
          columnGroupShow: "open",
          valueGetter: (params) => {
            if (!params) return;
            return rfqData.value[params.node.rowIndex].suppliers[
              column.headerName
            ]["actual_qty"];
          },
        });
        column["children"].push({
          headerName: translate("rfq.unit"),
          field: "actual_unit",
          columnGroupShow: "open",
          valueGetter: (params) => {
            if (!params) return;
            return rfqData.value[params.node.rowIndex].suppliers[
              column.headerName
            ]["actual_unit"];
          },
        });
        column["children"].push({
          headerName: translate("rfq.overview.pricePerUnit"),
          field: "actual_unit_price",
          columnGroupShow: "open",
          valueGetter: (params) => {
            if (!params) return;
            return rfqData.value[params.node.rowIndex].suppliers[
              column.headerName
            ]["actual_unit_price"];
          },
          valueFormatter: (params) => {
            if (!params.value) return "";
            return formatNumbers(+params.value);
          },
        });
        column["children"].push({
          headerName: translate("rfq.budget"),
          field: "total_budget",
          flex: 2,
          autoHeight: true,
          wrapText: true,
          minWidth: 150,
          columnGroupShow: "closed",
          cellClass: ["align-cell-center"],
          cellRendererFramework: OverviewHeader,
          cellRendererParams: {
            columnGroupName: column["headerName"],
            columnGroupTotal: totalColumn(column.headerName),
          },
        });
      }
    }
    customColumnDefs.value.push(...columnDefs);
  };

  const handleFooterColumn = () => {
    // Deep copy data
    const rfq = JSON.parse(JSON.stringify(rfqData.value[0]));
    // Loop through keys and set values to null
    Object.keys(rfq).forEach((i) => {
      supplierKeys.forEach((supplier) =>
        Object.keys(rfq["suppliers"][supplier]).forEach((z) => {
          rfq["suppliers"][supplier][z] = null;
        })
      );
      // Set all other properties that aren't suppliers to null
      if (i !== "suppliers") {
        rfq[i] = null;
      }
    });
    pinnedBottomRowData.value.push(rfq);
  };

  // Form Stuff

  const rfqForm = reactive({
    rfqNo: "",
    deadlineDate: "",
    workDate: "",
    rfqDescription: "",
  });

  const validationSchema = Yup.object().shape({
    rfqNo: Yup.string()
      .required()
      .label("No."),
    rfqDeadlineDate: Yup.string()
      .required()
      .label("Deadline Date"),
    rfqWorkDate: Yup.string()
      .required()
      .label("Work Date"),
    rfqDescription: Yup.string()
      .required()
      .label("Description"),
  });

  const handleRfqForm = (evt) => {
    console.log(evt);
    console.log(rfqForm);
    router.push({ name: "rounds" });
  };

  onMounted(() => {
    setTimeout(() => {
      rfqData.value = RFQ_DATA.response.data.rfqRows.data;
      getSupplierKeys();
      handleColumnDefs();
      handleFooterColumn();
      loading.value = false;
    }, 1500);
  });

  return {
    ...toRefs(rfqForm),
    handleRfqForm,
    validationSchema,
    gridApi,
    rfqData,
    colApi,
    loading,
    bodyOptions,
    setDateFormat,
    pinnedBottomRowData,
    customColumnDefs,
    componentKey,
    currentLang,
    translate,
  };
};

// https://www.ag-grid.com/javascript-data-grid/tree-data/
