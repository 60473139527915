
import { defineComponent, ref } from "vue";
import {
  formatNumbers,
} from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridActions",
  props: ['params'],
  setup(props) {
    const value = ref(props.params);

    const getMaxMin = () => {
      const budgetArray = Object.keys(value.value['data']['suppliers']).map(supplier => value.value['data']['suppliers'][supplier]['actual_budget'])
      const max = Math.max(...budgetArray)
      const min = Math.min(...budgetArray)

      return { max, min }
    }

    const compareBudget = (budgetValue) => {
      if (!budgetValue) return;
      const { max, min } = getMaxMin();

      if (budgetValue > min && budgetValue < max) {
        return 'text-black'
      }

      if (budgetValue >= max) {
        return 'text-danger'

      }

      if (budgetValue <= min) {
        return 'text-primary'
      }
    }

    const compareBudgetArrow = (budgetValue) => {
      if (!budgetValue) return;
      const { max, min } = getMaxMin();

      if (budgetValue >= max) {
        return 'bi-arrow-up text-danger'

      }

      if (budgetValue <= min) {
        return 'bi-arrow-down text-primary'
      }
    }

    const compareBudgetBadgeClass = (budgetValue) => {
      if (!budgetValue) return;
      const { max, min } = getMaxMin();

      if (budgetValue > min && budgetValue < max) {
        return null
      }

      if (budgetValue >= max) {
        return 'badge badge-danger'
      }

      if (budgetValue <= min) {
        return 'badge badge-primary'
      }
    }

    const compareBudgetBadgeText = (budgetValue) => {
      if (!budgetValue) return;
      const { max, min } = getMaxMin();

      if (budgetValue > min && budgetValue < max) {
        return null
      }

      if (budgetValue >= max) {
        return 'High'
      }

      if (budgetValue <= min) {
        return 'Low'
      }
    }

    const compareBudgetBadgeAltText = (budgetValue) => {
      if (!budgetValue) return;
      const { max, min } = getMaxMin();

      if (budgetValue >= max) {
        return 'Higher Than Quote'
      }

      if (budgetValue <= min) {
        return 'Lower Than Quote'
      }
    }

    return {
      compareBudgetArrow,
      compareBudgetBadgeAltText,
      compareBudgetBadgeText,
      compareBudgetBadgeClass,
      formatNumbers,
      compareBudget,
      value,

    };
  },
});
