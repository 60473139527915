
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from "element-plus";
import { translate } from "@/core/helpers/config";

export default defineComponent({
  name: "AgGridActions",
  props: ['params'],
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  setup(props) {
    const value = ref(props.params);
    const groupExpanded = ref(false);

    const expandOrCollapse = () => {
      const currentState = value.value.columnGroup
        .getOriginalColumnGroup()
        .isExpanded();
      value.value.setExpanded(!currentState);
    }

    const syncExpandButtons = () => {
      groupExpanded.value = value.value.columnGroup
        .getOriginalColumnGroup()
        .isExpanded();
    }

    onMounted(() => {
      value.value.columnGroup
        .getOriginalColumnGroup()
        .addEventListener('expandedChanged', syncExpandButtons.bind(this));
      syncExpandButtons();
    })

    onUnmounted(() => {
      value.value.columnGroup
        .getOriginalColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons.bind(this));
    })

    return {
      expandOrCollapse,
      groupExpanded,
      value,
      translate,
    };
  },
});
